body {
	background: linear-gradient(45deg, #f7f7f7, #e3e3e3);
}

.form-control {
	margin-bottom: 0.4rem;
}
.pagination {
	justify-content: center;
	border-radius: 5px;
	margin-top: 10px;
	li,
	a {
		cursor: pointer;
	}
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
